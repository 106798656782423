// @flow
import React from "react";
import { css } from "aphrodite";
import { Images, AppStyles } from "../../../theme";
import styles from "./TeamSecStyles";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { Button } from "react-bootstrap";
import { OurTeamSection } from "../../../components";
export default function TeamSecView(props) {
  return (
    <section
      className={`py-5 ${css([
        // styles.marginBottom,
        styles.TeamSec,
        // AppStyles.pxy_12,
      ])}`}
    >
      <div className={`container`}>
      
        <div className={css(styles.textHead)}>Frequently asked questions</div>
        <p className={css(styles.addText)}>
          1. How does an Employee Assistance Program benefit employees? <br />{" "}
          <br />
          EAPs provide access to professional counseling, support for mental
          health, financial advice, and legal guidance, helping employees manage
          personal challenges, reduce stress, and enhance overall well-being.{" "}
          <br /> <br />
          2. Is EAP counseling confidential? <br /> <br />
          Yes, EAP counseling is completely confidential. Employers do not have
          access to personal information discussed during counseling sessions,
          ensuring employees feel safe seeking help.
          <br /> <br />
          3. What issues can be addressed through EAP?
          <br /> <br />
          EAP services address a range of issues, including anxiety, depression,
          stress, work-related concerns, family and relationship problems,
          financial difficulties, and more. <br /> <br />
          4. How can employees access EAP services? <br /> <br />
          Employees can access our EAP services via phone, email, live chat, or
          in-person sessions. Our many programs offer 24/7 support to ensure
          help is available whenever needed. <br /> <br />
          5. Why organisations choose your EAP?
          <br /> <br />
          Our EAP services are user-friendly and designed to provide a
          measurable impact through a comprehensive support network. Our program
          helps employees experience a reduction in anxiety, depression, and
          absenteeism, and facilitates a smooth return to work after therapy.
        </p>
      
        <div className={css(styles.teamContainer)}>
          <div className={`mt-5 ${css(styles.teamHead)}`}>Meet The Team</div>

          <div className={css(styles.cardContent)}>
            Healthcity’s core team and its associates include qualified
            psychologists, therapists, nurses, doctors and other professionals,
            as well as, former service users. They have relevant training,
            skills, and experiences culturally and professionally.Below just
            some of our team members. Behind the experienced directors and
            managers below stand a number of awesome, dedicated non-executive
            directors and advisors, clinicians, University researchers and
            client support staff who make us all look good and achieving.
          </div>
        </div>
        <div className={css(styles.teamSectionView)}>
          <OurTeamSection isShortView={true} />
        </div>
        <div className={css(styles.teamBtnCont)}>
          <NavLink to={ROUTES.TEAM}>
            <Button className={css(styles.teamBtn)}>See All Team</Button>
          </NavLink>
        </div>
      </div>
    </section>
  );
}
