// @flow
import React from "react";
import { css } from "aphrodite";
import { Images, AppStyles } from "../../../theme";
import styles from "./WeHelpSecStyles";
export default function WeHelpSecView(props) {
  return (
    <section
      id="cbt"
      className={`pt-5 ${css([
        // styles.marginBottom,
        styles.WeHelpSec,
        // AppStyles.pxy_12,
      ])}`}
    >
      <div className={`container `}>
        <div className={css(styles.CBTInnerCont)}>
          <div className={css(styles.CBTTextCont)}>
            <div className={css(styles.CBTHeading)}>
              {/* WE HELP TO PLAN AND SUPPORT STAFF TO */}
              We help to plan and support staff to:
            </div>

            <ul className={css(styles.listStyle)}>
              <li className={css(styles.exampleList)}>
                <span className={css(styles.exampleListSpan)}>
                  Reduce barrier to returning to work or to maintain attendance
                  and improve performance at work.
                </span>
              </li>
              <li className={css(styles.exampleList)}>
                <span className={css(styles.exampleListSpan)}>
                  Identify additional support in the workplace.
                </span>
              </li>

              <li className={css(styles.exampleList)}>
                <span className={css(styles.exampleListSpan)}>
                  Reduce barriers against work stress.
                </span>
              </li>

              <li className={css(styles.exampleList)}>
                <span className={css(styles.exampleListSpan)}>
                  Take advantage of early intervention to prevent or reduce
                  small issues at work or at home from evolving into big impacts
                  on their performance or into sick absence and high production
                  or service delivery costs.
                </span>
              </li>
            </ul>
          </div>

          <div className={css(styles.CBTInerContTwo)}>
            <img src={Images.help_section} className={css(styles.cbtImgOne)} />
          </div>
        </div>
        {/* New Text Added */}
        <div>
          <h2 className={css(styles.etextHead)}>
            Personalised Care for a <br />
            Thriving, Energized Workforce
          </h2>
          <h3 className={css(styles.addText)}>
            <p className={css(styles.pText)}>Enhance Employee Well-Being </p> An
            EAP provides mental health support, stress management resources, and
            counseling services, helping employees cope with personal and
            professional challenges. This support improves overall well-being,
            creating a healthier workplace environment. <br />
            <p className={css(styles.pText)}>
              Boost Productivity and Engagement
            </p>
            By addressing personal and work-related issues, EAPs help employees
            remain focused and motivated. This leads to improved productivity
            and job satisfaction, as employees feel more supported and valued by
            their organization. <br />
            <p className={css(styles.pText)}>Reduce Absenteeism and Turnover</p>
            EAPs offer timely help for employees dealing with stress or life
            challenges, reducing absenteeism. With a supportive workplace,
            employees are more likely to stay committed, reducing turnover rates
            effectively.
          </h3>
        </div>

        {/* New Text Added */}

        <div className={css(styles.textHead)} style={{ marginBottom: "20px" }}>
          Structured EAP Counselling for Your Employees
        </div>
        <p className={css(styles.addText)}>
          Our Employee Assistance Program (EAP) delivers structured counselling
          to support your staff's mental well-being. With an in-house team of
          expert counsellors and a broad network of active professionals across
          the UK, we ensure your employees receive the care they need.
          Recognizing the diverse needs of your workforce, we offer:
          <br />
          <br />
          <li> Flexible counselling options beyond face-to-face sessions </li>
          <li> Structured therapy with tailored sessions per person </li>
          <li>
            Confidential email support, or convenient telephone and live chat
            counselling
          </li>
          <br />
          Empower your team to overcome personal challenges, reduce workplace
          stress, and improve overall productivity with a confidential,
          professional service they can trust.
          <br />
          <br />
          Our EAP is a commitment to employee wellness and a vibrant work
          culture. It acts as a trusted friend, a helping hand, and a wise
          counsellor, assisting employees to navigate life’s challenges,
          enhancing their productivity, and ultimately, their happiness.
        </p>
      </div>
    </section>
  );
}
